<template>
  <div class="error-wrap">
    <img src="images/404.png" alt="404 image">
    <span class="tt">찾을 수 없는 페이지 입니다.</span>
    <span class="ts">요청하신 페이지가 사라졌거나, 잘못된 경로를 이용하셨습니다.</span>
    <a href="/" class="btn btn-primary">홈으로 이동</a>
  </div>
</template>

<script>
export default {
  name: 'Error404'
}
</script>

<style scoped>

</style>
